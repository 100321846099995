import React from 'react';
import { Box, Typography, LinearProgress, Paper, Avatar } from '@mui/material';

interface ProgressBarTileProps {
    id: string;
    title: string;
    currentAmount: number;
    targetAmount: number;
    icon: string;
    backgroundColor: string;
    isSelected: boolean;
    onSelect: (id: string) => void;
}

const ProgressBarTile: React.FC<ProgressBarTileProps> = ({
                                                             id,
                                                             title,
                                                             currentAmount,
                                                             targetAmount,
                                                             icon,
                                                             backgroundColor,
                                                             isSelected,
                                                             onSelect,
                                                         }) => {
    const progress = (currentAmount / targetAmount) * 100;

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                margin: 1,
                backgroundColor,
                cursor: "pointer",
                border: isSelected ? "2px solid #000" : "2px solid transparent",
                transition: "border 0.3s",
            }}
            onClick={() => onSelect(id)}
        >
            <Box display="flex" alignItems="center">
                <Avatar src={icon} sx={{ marginRight: 2 }} />
                <Typography variant="h6">{title}</Typography>
            </Box>
            <Box mt={2}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="body2" align="center">
                    {currentAmount} / {targetAmount} zł
                </Typography>
            </Box>
        </Paper>
    );
};

export default ProgressBarTile;
