import React, { useEffect, useState } from "react";
import IDonatePageInfo from "../types/IDonatePageInfo";
import donatePageInfoApi from "../Api";
import { createTsForm, createUniqueFieldSchema } from "@ts-react/form";
import { z } from "zod";
import TextFormField from "../form/field/TextFormField";
import MultiLineFormTextField from "../form/field/MultiLineFormTextField";
import PaymentCheckboxField from "../form/field/PaymentCheckboxField";
import DonateCustomFormComponent from "../form/component/DonateCustomFormComponent";
import IPaymentRequest from "../types/IPaymentRequest";
import paymentsApi from "../PaymentsApi";
import {
    Alert,
    Avatar,
    Box,
    Container,
    createTheme,
    CssBaseline,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { FidgetSpinner } from "react-loader-spinner";
import IWidget from "../types/IWidget";
import NumberFormField from "../form/field/NumberFormField";
import ProgressBarField from "../form/field/ProgressBarField";
import { useNavigate, useParams } from "react-router-dom";
import IPaymentMethod from "../types/IPaymentMethod";

export const Donate = () => {
    const initialDonatePageInfo: IDonatePageInfo = {
        donatePageName: "Error",
        minimalAmount: -1,
        userId: "",
        maxCharactersTextDonate: 0,
        paymentMethods: [],
        avatar: "",
        publicUserName: "No Data",
    };

    const [donatePageInfo, setDonatePageInfo] = useState<IDonatePageInfo>(initialDonatePageInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [progressBars, setProgressBars] = useState<IWidget[]>([]);

    const { publicUsername } = useParams() as { publicUsername: string };
    const navigate = useNavigate();

    useEffect(() => {
        if (!publicUsername) return;

        const fetchDonatePageData = async () => {
            try {
                const donateInfo = await donatePageInfoApi.getDonatePageInfo(publicUsername);
                setDonatePageInfo(donateInfo.data);
                const progressBars = await donatePageInfoApi.getProgressBars(donateInfo.data.userId);
                setProgressBars(progressBars.data);
            } catch {
                navigate("/");
            }
        };

        fetchDonatePageData();
    }, [publicUsername, navigate]);

    if (donatePageInfo.userId === "") {
        return (
            <FidgetSpinner
                visible={true}
                height="80"
                width="80"
                ariaLabel="fidget-spinner-loading"
            />
        );
    }

    const TextFormSchema = createUniqueFieldSchema(
        z.string({ required_error: "Podaj treść wiadomości" })
            .max(donatePageInfo.maxCharactersTextDonate, `Maksymalna długość wiadomości to ${donatePageInfo.maxCharactersTextDonate}`),
        "text"
    );

    const ProgressBarSchema = createUniqueFieldSchema(
        z.string().optional().describe("Wybierz cel darowizny"),
        "progressBarId"
    );

    const paymentMethodOptions: IPaymentMethod[] = donatePageInfo.paymentMethods.map((method) => ({
        name: method.name,
        iconHref: method.iconHref,
    }));

    const paymentMethodEnum = paymentMethodOptions.length > 0
        ? z.enum(paymentMethodOptions.map((m) => m.name) as [string, ...string[]])
        : z.enum(["default"]);

    const mapping = [
        [z.string(), TextFormField],
        [z.number(), NumberFormField],
        [TextFormSchema, MultiLineFormTextField],
        [paymentMethodEnum, PaymentCheckboxField],
        [ProgressBarSchema, ProgressBarField],
    ] as const;

    const MyForm = createTsForm(mapping, { FormComponent: DonateCustomFormComponent });

    const DonateSchema = z.object({
        nickname: z.string({ required_error: "Podaj swój nick" })
            .min(3, `Minimalna długość nicka to 3 znaki`)
            .describe("Nick"),
        email: z.string({ required_error: "Podaj swój adres email" })
            .email("Wprowadź swój adres email")
            .describe("E-mail"),
        amount: z.number({ required_error: "Podaj kwotę" })
            .min(donatePageInfo.minimalAmount, `Minimalna kwota to ${donatePageInfo.minimalAmount} zł`)
            .describe("Kwota"),
        text: TextFormSchema.describe("Wiadomość"),
        paymentMethod: paymentMethodEnum.describe("Metoda płatności"),
        progressBarId: ProgressBarSchema.describe("Cel darowizny"),
    });

    function onSubmit(data: z.infer<typeof DonateSchema>) {
        setIsLoading(true);
        setErrorMessage("");

        const { nickname, email, amount, text, paymentMethod, progressBarId } = data;

        const request: IPaymentRequest = {
            donateType: "TEXT",
            amount,
            message: text || "",
            senderEmail: email,
            title: nickname,
            paymentMethod,
            publicUserName: publicUsername,
            blikCode: "",
            firstName: nickname,
            lastName: nickname,
            browserClientMeta: {
                userScreenResolution: `${window.innerWidth}x${window.innerHeight}`,
            },
            userWidgetIds: progressBarId && progressBarId !== "none" ? [progressBarId] : [],
        };

        paymentsApi
            .createPayment(request)
            .then((response: any) => {
                setIsLoading(false);
                if (response.status === 200) {
                    window.location.href = response.data.approveUrl;
                }
            })
            .catch(() => {
                setIsLoading(false);
                setErrorMessage("Wystąpił błąd. Proszę spróbować ponownie.");
            });
    }

    const defaultTheme = createTheme();

    if (isLoading) {
        return <FidgetSpinner visible={true} height="80" width="80" ariaLabel="fidget-spinner-loading" />;
    }

    return (
        <main>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CssBaseline />
                        <Avatar
                            sx={{ m: 1, bgcolor: "secondary.main", width: 128, height: 128 }}
                            src={donatePageInfo.avatar}
                            alt={donatePageInfo.publicUserName}
                        />
                        <Typography component="h1" variant="h5">
                            {donatePageInfo.donatePageName}
                        </Typography>
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                        <MyForm
                            schema={DonateSchema}
                            onSubmit={onSubmit}
                            props={{
                                progressBarId: {
                                    options: progressBars.map((pb) => ({
                                        value: pb.id,
                                        title: pb.settings.targetName,
                                        imgLink: pb.settings.icon,
                                        currentAmount: parseFloat(pb.settings.currentAmount) || 0,
                                        targetAmount: parseFloat(pb.settings.targetAmount) || 1,
                                        backgroundColor: pb.settings.targetColorBackground,
                                    })),
                                },
                                paymentMethod: {
                                    options: paymentMethodOptions,
                                },
                                text: {
                                    maxCharacters: donatePageInfo.maxCharactersTextDonate,
                                },
                            }}
                        />
                    </Box>
                </Container>
            </ThemeProvider>
        </main>
    );
};
