import {Alert, Button, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import processingImage from '../icons/dollar-stack.gif'
import {useNavigate, useParams} from "react-router-dom";
import paymentsApi from "../PaymentsApi";
import {monitorStatus} from "../util/MonitorStatus";

export const ProcessingForPayment = () => {
    const [data, setData] = useState({
        id: '0',
        publicUsername: undefined
    });
    const { paymentId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (paymentId) {
            paymentsApi.getPayment(paymentId)
                .then(res => {
                    setData(res.data);
                })
                .catch(e => console.log("Cannot obtain payment status"));
        }
    }, [paymentId]);

    useEffect(() => {
        if (data.id === '0' || data.id === null) return;

        const controller = new AbortController();
        const signal = controller.signal;

        (async () => {
            let payment = () => paymentsApi.getPayment(data.id);
            let validate = (result: any) => {
                return !(result.data.paymentStatus === 'DONE' ||
                    result.data.paymentStatus === 'ERROR_INTERNAL' ||
                    result.data.paymentStatus === 'ERROR_EXTERNAL' ||
                    signal.aborted);
            }
            let response = await monitorStatus(payment, validate, 1000);
            if (response.data.paymentStatus === 'DONE') {
                navigate(`/success/${data.id}/${data.publicUsername}`, {state: response.data});
            } else {
                navigate("/error", {state: response.data});
            }
        })();

        return () => controller.abort();
    }, [data]);

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={12}>
                <img src={processingImage} width='256px' />
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info">Przetwarzanie płatności</Alert>
            </Grid>

            {/*DEV ONLY CONFIRM MANUALLY PAYMENT*/}
            {/*<Grid item xs={12}>*/}
            {/*    <Button sx={{ m: 3}} variant="outlined" color="error" onClick={() => {*/}
            {/*        paymentsApi.confirmPayment(data.id)*/}
            {/*            .then(response => {*/}
            {/*                console.log("Confirmed manually payment");*/}
            {/*            })*/}
            {/*            .catch(reason => console.log("ERROR on manually payment approve"));*/}
            {/*    }} > Confirm payment id {data.id} manually </Button>*/}
            {/*</Grid>*/}
        </Grid>
    );
}
