import { useDescription, useTsController } from "@ts-react/form";
import { Grid, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material";
import IPaymentMethod from "../../types/IPaymentMethod";

export default function PaymentCheckboxField({ options }: { options: IPaymentMethod[] }) {
    const { field: { onChange, value }, error } = useTsController<string>();
    const { label } = useDescription();

    return (
        <FormControl component="fieldset" sx={{ width: "100%", mb: 3 }} error={!!error}>
            <FormLabel component="legend" sx={{ mb: 1 }}>
                {label}
            </FormLabel>
            <RadioGroup row sx={{ justifyContent: "center", gap: 2 }}>
                <Grid container spacing={2}>
                    {options.map((method, index) => (
                        <Grid item key={index}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={value === method.name}
                                        icon={
                                            <img
                                                src={method.iconHref}
                                                alt={method.name}
                                                style={{
                                                    width: "100px",
                                                    height: "50px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "8px",
                                                }}
                                            />
                                        }
                                        checkedIcon={
                                            <img
                                                src={method.iconHref}
                                                alt={method.name}
                                                style={{
                                                    width: "120px",
                                                    height: "60px",
                                                    border: "2px solid #007BFF",
                                                    borderRadius: "8px",
                                                }}
                                            />
                                        }
                                    />
                                }
                                label=""
                                value={method.name}
                                onChange={() => onChange(method.name)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </RadioGroup>
            {error?.errorMessage && <FormHelperText>{error.errorMessage}</FormHelperText>}
        </FormControl>
    );
}
