import { Alert, Grid, Button } from "@mui/material";
import React from "react";
import successImage from '../icons/successfully-done.gif';
import { useParams, useNavigate } from "react-router-dom";

export const SuccessForPayment = () => {
    const { paymentId, publicUsername } = useParams();
    const navigate = useNavigate();

    const handleReturn = () => {
        navigate(`/user/${publicUsername}`);
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={12}>
                <img src={successImage} width='256px' alt="Payment Successful" />
            </Grid>
            <Grid item xs={12}>
                <Alert severity="success">Twoja płatność została pomyślnie przetworzona.</Alert>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleReturn}>
                    Wróć do strony wpłat
                </Button>
            </Grid>
        </Grid>
    );
};
