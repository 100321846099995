import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Donate} from "./pages/Donate";
import {NotFound} from "./pages/NotFound";
import {Success} from "./pages/Success";
import {Processing} from "./pages/Processing";
import {Error} from "./pages/Error";
import {ProcessingForPayment} from "./pages/ProcessingForPayment";
import {SuccessForPayment} from "./pages/SuccessForPayment";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/user/:publicUsername" element={<Donate />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/success/:paymentId/:publicUsername" element={<SuccessForPayment />} />
                    <Route path="/processing" element={<Processing />} />
                    <Route path="/processing/:paymentId" element={<ProcessingForPayment />} />
                    <Route path="/error" element={<Error />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App;
