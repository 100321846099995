import http from "./http-common";
import IDonatePageInfo from "./types/IDonatePageInfo";
import IWidget from "./types/IWidget";

const getDonatePageInfo = (userName: string) => {
    return http.get<IDonatePageInfo>(`/users/${userName}/donate-page-info-public`);
}

const getProgressBars = (userId: string) => {
    return http.get<IWidget[]>(`/users/${userId}/widgets/PROGRESS`);
}

const Api = {
    getDonatePageInfo,
    getProgressBars
};

export default Api;