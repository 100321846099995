import {useDescription, useTsController} from "@ts-react/form";
import {FormControl, FormLabel, Grid, Typography} from "@mui/material";
import React from "react";
import ProgressBarTile from "./ProgressBarTile";

export default function ProgressBarField({ options }: { options: any[] }) {
    const {
        field: { value, onChange }, error
    } = useTsController<string>();
    const { label } = useDescription();

    if (options[0] === undefined) {
        return (
            <></>
        );
    }

    return (
        <FormControl component="fieldset" sx={{ width: "100%", mb: 3 }} error={!!error}>
            <FormLabel component="legend" sx={{ mb: 1 }}>
                {label}
            </FormLabel>
            <Grid container spacing={2}>
                {options.map((bar) => (
                    <Grid item xs={12} sm={6} md={4} key={bar.value}>
                        <ProgressBarTile
                            id={bar.value}
                            title={bar.title}
                            currentAmount={bar.currentAmount}
                            targetAmount={bar.targetAmount}
                            icon={bar.imgLink}
                            backgroundColor={bar.backgroundColor || "#f5f5f5"}
                            isSelected={value === bar.value}
                            onSelect={() => onChange(bar.value)}
                        />
                    </Grid>
                ))}
                {/* Kafelek dla opcji „Brak celu” */}
                <Grid item xs={12} sm={6} md={4}>
                    <ProgressBarTile
                        id="none"
                        title="Brak celu"
                        currentAmount={0}
                        targetAmount={0}
                        icon="https://via.placeholder.com/64"
                        backgroundColor="#e0e0e0"
                        isSelected={value === "none"}
                        onSelect={() => onChange("none")}
                    />
                </Grid>
            </Grid>
        </FormControl>
    );
}
